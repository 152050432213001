export default {
  text: '#111',
  primary: '#d91a1c',
  secondary: '#292929',
  tertiary: '#618354',
  background: 'white',
  backgroundSecondary: '#202020',
  light: '#FFF',
  dark: '#020303'
}
